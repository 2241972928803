<template>
  <section class="profile">
    <div class="back" v-if="showResetPassword" @click="showResetPassword = false">
      <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 1L1 8L8 15" stroke="white" stroke-width="2" stroke-linejoin="round" />
      </svg>
    </div>
    <Preloader v-if="!user" />
    <div class="portrait">
      <div class="page-title">
        <h1>Профиль игрока</h1>
      </div>
      <div class="statistic__wrapper">
        <div class="statistic" v-if="!showResetPassword">

          <div class="statistic__item">
            <div class="my_place">{{ user.place || place }}</div>
            <span class="statistic__name">Место</span>
          </div>

          <div class="statistic__item">
            <div class="my_score">{{ user.score || 0 }}</div>
            <span class="statistic__name">Баллы</span>
          </div>
        </div>

      </div>
      <br>
      <br>
      <br>
      <div v-if="false && !showResetPassword" class="container">
        <div class="inputs">
          <div class="input__block" v-for="input of inputs" :key="input.id">
            <span class="input__label">{{ input.name }}</span>
            <input v-if="input.mask" :type="input.type" v-mask="input.mask" v-model="user[input.id]" />
            <input v-else :type="input.type" v-model="user[input.id]" />
            <i class="icon icon-edit"></i>
          </div>
        </div>
        <!-- <div>Статус: {{ user.type }}</div>
        <label class="status">
          <input type="checkbox" @change="changeTrainingStatus" v-model="user.training" />
          <span>НЕ показывать обучающее видео в главном меню</span>
        </label>
        <div v-if="user.type === 'Премиум'" class="subscribe">
          Заканчивается: {{ user.subscription ? dateFilter(user.subscription, 'datetime') : "никогда" }}
        </div> -->
        <div class="actions">
          <button class="btn btn-main" @click="saveProfile">Сохранить изменения</button>
          <!-- <button class="btn btn-main" @click="showChangePassword">
            Сменить пароль
          </button> -->
          <!-- <button class="btn btn-gradient" @click="unsubscribe" v-if="user.type === 'Премиум'">
            Отменить подписку
          </button> -->
          <!-- <button class="btn btn-gradient" @click="subscribe" v-else>
            Оформить подписку
          </button> -->
          <!-- <button class="btn btn-main" @click="$router.push('/sounds')" v-if="user.type === 'Премиум'">
            Скачать песни
          </button>
          <button class="btn btn-main" @click="$router.push('/stocks')" v-if="user.type === 'Премиум'">
            Акции
          </button> -->
        </div>
      </div>
      <div class="container" v-if="showResetPassword">
        <div class="title">Смена пароля</div>
        <div class="inputs">
          <div class="input__block" v-for="input of pass_inputs" :key="input.id">
            <span>{{ input.name }}</span>
            <input :type="input.type" v-model="password[input.id]" />
          </div>
        </div>
        <div class="actions">
          <button class="btn btn-gradient" @click="changePassword">
            Сохранить
          </button>
        </div>
      </div>
    </div>
<!--    <div class="landscape" v-if="orientation === 'landscape'">
      <div class="left" v-if="!showResetPassword">
        <div class="me_info">
          <div class="left">
            <div class="my my_position"></div>
            <span>Место</span>
            <div class="my my_place">{{ user.place || 0 }}</div>
          </div>
          <div class="middle">
            <div>Вы</div>
          </div>
          <div class="right">
            <span>Баллы</span>
            <div class="my my_score">{{ user.score || 0 }}</div>
          </div>
        </div>
        <div class="bottom">
          <div class="inputs">
            <div class="input__block" v-for="input of inputs" :key="input.id">
              <span>{{ input.name }}</span>
              <input v-if="input.mask" :type="input.type" v-mask="input.mask" v-model="user[input.id]" />
              <input v-else :type="input.type" v-model="user[input.id]" />
            </div>
          </div>
          <div>Статус: {{ user.type }}</div>
          <label class="status">
            <input type="checkbox" @change="changeTrainingStatus" v-model="user.training" />
            <span>НЕ показывать обучающее видео в главном меню</span>
          </label>
          <div v-if="user.type === 'Премиум'" class="subscribe">
            Заканчивается: {{ user.subscribe || "никогда" }}
          </div>
        </div>
      </div>
      <div v-if="!showResetPassword" class="container">
        <div class="actions">
          <button class="btn btn-main" @click="saveProfile">Сохранить</button>
          <button class="btn btn-main" @click="showChangePassword">
            Сменить пароль
          </button>
          <button class="btn btn-gradient" v-if="user.type === 'Премиум'">
            Отменить подписку
          </button>
          <button class="btn btn-gradient" @click="subscribe" v-else>
            Оформить подписку
          </button>
          <button class="btn btn-main" @click="$router.push('/sounds')" v-if="user.type === 'Премиум'">
            Скачать песни
          </button>
          <button class="btn btn-main" @click="$router.push('/stocks')" v-if="user.type === 'Премиум'">
            Акции
          </button>
        </div>
      </div>
      <div class="container password" v-if="showResetPassword">
        <div class="title">Смена пароля</div>
        <div class="inputs">
          <div class="input__block" v-for="input of pass_inputs" :key="input.id">
            <span>{{ input.name }}</span>
            <input :type="input.type" v-model="password[input.id]" />
          </div>
        </div>
        <div class="actions">
          <button class="btn btn-gradient" @click="changePassword">
            Сохранить
          </button>
        </div>
      </div>
    </div>-->
  </section>
</template>

<script>
import axios from 'axios';
import Preloader from "../Preloader";

import { mapGetters } from 'vuex';

export default {
  name: "ProfileMain",
  data() {
    return {
      showResetPassword: false,
      place: 0,
      status: this.$store.getters.user.sound_playing,
      password: {
        old: "",
        new: "",
        replace: "",
      },
      inputs: [
        { id: "login", name: "Логин/Номер телефона", type: "text" },
        { id: "email", name: "E-mail", type: "email" },
        // {
        //   id: "phone",
        //   name: "Телефон",
        //   type: "phone",
        //   mask: "+7(###)###-##-##",
        // },
      ],
      pass_inputs: [
        { id: "old", name: "Текущий пароль", type: "password" },
        { id: "new", name: "Новый пароль", type: "password" },
        { id: "replace", name: "Повторите новый пароль", type: "password" },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'axiosOptions',
      'orientation',
      'user',
    ]),
  },

  created() {
    this.checksubscribe();
  },
  mounted() {
    this.$store.dispatch("checkGameDuration");
    this.getUserRating();
    if (this.user && this.user.login === "guest") {
      this.$router.push("/");
    }
  },
  methods: {
    zeroPadFilter(n, width = 2, z) {
      z = z || "0";
      n = n + "";
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    },
    dateFilter(value, format, year = true) {
      let date = new Date(value);
      let options = '';
      if (format.includes('date')) {
        options += `${this.zeroPadFilter(date.getDate(), 2)}.${this.zeroPadFilter(date.getMonth() + 1, 2)}`;
        if (year) {
          options += `.${String(date.getFullYear()).substring(2)}`;
        }
      }
      if (format.includes('time')) {
        options += ` ${this.zeroPadFilter(date.getHours(), 2)}:${this.zeroPadFilter(date.getMinutes(), 2)}`;
      }
      return options;
    },
    isMD5(inputString) {
      return (/[a-fA-F0-9]{32}/).test(inputString);
    },
    checksubscribe() {
      const query = this.$router.currentRoute.query;
      if (query && query.sign && this.isMD5(query.sign) && query.success === 'true') {
        axios
          .put('/api/gamer/subscribe',
            query,
            {
              headers: {
                token: this.$store.getters.token,
              }
            })
          .then(res => {
            location.reload();
          })
          .catch(err => {
            this.$router.replace({
              ...this.$router.currentRoute,
              query: {

              }
            })
          })
      }
    },
    changeTrainingStatus() {
      axios.put(
        `${this.BASE_URL}/api/gamer/training`,
        { training: this.user.training },
        {
          headers: {
            token: this.$store.getters.token,
          },
        }
      ).catch((err) => {
        console.error(err);
      });
    },
    subscribe() {
      window.location.assign(`https://auth.advmusic.com/?service=paramusic&return_url=https://muzscrabble.ru/profile&payload=${this.user._id}`);
      window.location = `https://auth.advmusic.com/?service=paramusic&return_url=https://muzscrabble.ru/profile&payload=${this.user._id}`;
    },
    unsubscribe() {
      axios
        .post('/api/gamer/unsubscribe', {},
          {
            headers: {
              token: this.$store.getters.token,
            },
          })
        .then(res => {
          this.user = res.data;
          this.$store.dispatch("getUserStaticData", this.user);
        })
    },
    async getUserRating() {
      try {
        const params = {};
        params.userId = this.user._id;
        const options = { ...this.axiosOptions, params };
        const response = await axios.get(`/api/rating/all/top`, options);
        this.place = response.data.find((el) => el._id === this.user._id).place;
      } catch(err) {
        console.error(err);
      };
    },
    saveProfile() {
      if (this.user.login === "") {
        return this.$bus.$emit("showNotification", {
          status: "danger-game",
          message: "Введите логин!",
        });
      }
      if (this.user.email === "") {
        return this.$bus.$emit("showNotification", {
          status: "danger-game",
          message: "Введите почту!",
        });
      }
      axios.put(`/api/user`, this.user, {
        headers: {
          token: this.$store.getters.token,
        },
      })
        .then((res) => {
          this.$bus.$emit("showNotification", {
            status: "success-game",
            message: "Ваши данные успешно изменены!",
          });
        })
        .catch((err) => {
          this.$bus.$emit("showNotification", {
            status: "danger-game",
            message: err.response.data,
          });
        });
    },
    showChangePassword() {
      this.showResetPassword = !this.showResetPassword;
    },
    changePassword() {
      if (this.password.old === "") {
        return this.$bus.$emit("showNotification", {
          status: "danger-game",
          message: "Введите текущий пароль!",
        });
      }
      if (this.password.new === "") {
        return this.$bus.$emit("showNotification", {
          status: "danger-game",
          message: "Введите новый пароль!",
        });
      }
      axios.post(`/api/user/password`, this.password, {
        headers: {
          token: this.$store.getters.token,
        },
      })
        .then((res) => {
          this.$bus.$emit("showNotification", {
            status: "success-game",
            message: "Ваш пароль успешно изменён!",
          });
        })
        .catch((err) => {
          this.$bus.$emit("showNotification", {
            status: "danger-game",
            message: err.response.data,
          });
        });
    },
  },

  components: {
    Preloader,
  },
};
</script>

<style lang="scss" scoped>
.portrait {
  padding: 0 35px;
}

.input__label{
  color: #14142B !important
}
.page-title {

  padding-top: 60px;
  padding-left: 0;
  color: #14142B;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
}

.statistic {
  display: flex;
  justify-content: space-around;
  margin-top: 35px;
  gap: 2px
}

.statistic__item {
  background-color: #EFF0F7;
  flex: auto;
  padding: 10px;
  color: #14142B;
  font-weight: 600;
  text-align: center;

}

.statistic__item:first-child {
  border-radius: 10px 0 0 10px;
  //margin-right: 1px;
}

.statistic__item:last-child {
  border-radius: 0 10px 10px 0;
  //margin-left: 1px;
}

.statistic__name {
  font-weight: 400;
}
.icon-edit {

  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url(../../../assets/Edit.svg);
  background-position: 0 0;
  background-size: contain;
  position: absolute;
  right: 10px;
  top:35px;
}
.profile {
  height: 100%;
  padding-top: 20px;

  .portrait {
    padding-bottom: 30px;
  }

  .landscape {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;

    >.left {
      height: 100%;
      width: 45%;

      .me_info {
        margin-bottom: 20px;
      }
    }

    .bottom {
      padding-bottom: 15px;
    }

    .container {
      width: 45%;
      height: 100%;
    }

    .password {
      height: 100%;
      width: 100%;
      padding-top: 10px;
      padding-left: 25px;

      .title {
        font-size: 25px;
        margin-bottom: 60px;
      }

      .inputs {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .input__block {
          width: 48%;
        }
      }

      .actions {
        margin-top: 0;
        display: flex;
        align-items: flex-end;
        position: relative;
        top: -54px;
        right: 25px;

        .btn {
          width: 40% !important;
        }
      }
    }
  }

  .status {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 10px;

    >input::before {
      border-color: #fff;
    }

    >span {
      margin-left: 10px;
    }
  }

  .back {
    position: absolute;
    top: 25px;
    left: 25px;
    cursor: pointer;
  }

  .inputs {
    margin-bottom: 15px;
  }

  .title {
    font-size: 26px;
    margin-top: 35px;
    margin-bottom: 30px;
  }

  .subscribe {
    font-size: 14px;
    margin-top: 5px;
  }

  .actions {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      width: 85% !important;
    }

    button:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .me_info {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;

    .my {
      background: rgba(255, 255, 255, 0.2);
      color: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .left {
      span {
        font-size: 12px;
        position: relative;
        left: 13px;
        top: 20px;
      }

      .my_position {
        background: transparent;
        width: 45px;
        height: 45px;
        margin-bottom: 10px;
      }

      .my_place {
        width: 62px;
        height: 62px;
      }
    }

    .middle {
      font-weight: 500;
      font-size: 30px;
      padding-top: 25px;
    }

    .right {
      align-self: flex-end;

      span {
        font-size: 12px;
        position: relative;
        left: 20px;
        top: 23px;
      }

      .my_score {
        width: 77px;
        height: 77px;
        font-size: 17px;
      }
    }
  }
}
</style>