<template>
  <ProfileMain />
</template>

<script>
import axios from "axios";
import ProfileMain from "@/components/game/profile/ProfileMain";

export default {
  name: "Profile",
  components: {
    ProfileMain,
  },
};
</script>

<style>
</style>